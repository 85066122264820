import React from "react";

function StepOne() {
  return (
    <>
      <input type="text" />
    </>
  );
}

export default StepOne;
