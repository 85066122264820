export const PROJECT_CREATE_REQUEST = "PROJECT_CREATE_REQUEST";
export const PROJECT_CREATE_SUCESS = "PROJECT_CREATE_SUCESS";
export const PROJECT_CREATE_FAIL = "PROJECT_CREATE_FAIL";
export const PROJECT_CREATE_RESET = "PROJECT_CREATE_RESET";

export const PROJECT_DETAILS_REQUEST = "PROJECT_DETAILS_REQUEST";
export const PROJECT_DETAILS_SUCESS = "PROJECT_DETAILS_SUCESS";
export const PROJECT_DETAILS_FAIL = "PROJECT_DETAILS_FAIL";

export const PROJECT_LIST_MY_REQUEST = "PROJECT_LIST_MY_REQUEST";
export const PROJECT_LIST_MY_SUCESS = "PROJECT_LIST_MY_SUCESS";
export const PROJECT_LIST_MY_FAIL = "PROJECT_LIST_MY_FAIL";
export const PROJECT_LIST_MY_RESET = "PROJECT_LIST_MY_RESET";

export const PROJECT_CREATE_COLLAB_REQUEST = "PROJECT_CREATE_COLLAB_REQUEST";
export const PROJECT_CREATE_COLLAB_SUCESS = "PROJECT_CREATE_COLLAB_SUCESS";
export const PROJECT_CREATE_COLLAB_FAIL = "PROJECT_CREATE_COLLAB_FAIL";
export const PROJECT_CREATE_COLLAB_RESET = "PROJECT_CREATE_COLLAB_RESET";

export const PROJECT_GET_COLLAB_REQUEST = "PROJECT_GET_COLLAB_REQUEST";
export const PROJECT_GET_COLLAB_SUCESS = "PROJECT_GET_COLLAB_SUCESS";
export const PROJECT_GET_COLLAB_FAIL = "PROJECT_GET_COLLAB_FAIL";
export const PROJECT_GET_COLLAB_RESET = "PROJECT_GET_COLLAB_RESET";

export const PROJECT_GET_ORG_REQUEST = "PROJECT_GET_ORG_REQUEST";
export const PROJECT_GET_ORG_SUCESS = "PROJECT_GET_ORG_SUCESS";
export const PROJECT_GET_ORG_FAIL = "PROJECT_GET_ORG_FAIL";
export const PROJECT_GET_ORG_RESET = "PROJECT_GET_ORG_RESET";

export const PROJECT_UPDATE_COLLAB_REQUEST = "PROJECT_UPDATE_COLLAB_REQUEST";
export const PROJECT_UPDATE_COLLAB_SUCESS = "PROJECT_UPDATE_COLLAB_SUCESS";
export const PROJECT_UPDATE_COLLAB_FAIL = "PROJECT_UPDATE_COLLAB_FAIL";
export const PROJECT_UPDATE_COLLAB_RESET = "PROJECT_UPDATE_COLLAB_RESET";

export const PROJECT_UPDATE_PROFILE_REQUEST = "PROJECT_UPDATE_PROFILE_REQUEST";
export const PROJECT_UPDATE_PROFILE_SUCESS = "PROJECT_UPDATE_PROFILE_SUCESS";
export const PROJECT_UPDATE_PROFILE_FAIL = "PROJECT_UPDATE_PROFILE_FAIL";
export const PROJECT_UPDATE_PROFILE_RESET = "PROJECT_UPDATE_PROFILE_RESET";
