import React from "react";

function InsideLoader() {
  console.log("hllo world");
  return (
    <div className="inside-loader-div">
      <span class="loader-one"></span>
    </div>
  );
}

export default InsideLoader;
